<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="DED_TYPE"
      tooltip-title="DED_TYPE"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(deductionTypeCount)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="head in tableHeads" :key="head">
            <span v-i18n="salary">{{ head }}</span>
          </TH>
          <TH>Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(type, index) in deductionTypeList" :key="index">
          <TD>
            {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ type.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <TableAction
              :idx="index"
              :current-user="type"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <SalaryTypes
      v-if="showSalaryType"
      :header="'Deduction'"
      :editable-object="currentTypeActionBased"
      @update="fetchDeductionTypes"
    />
    <Pagination
      v-if="deductionTypeCount > 9"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(deductionTypeCount, filteredRecordLimit)"
      @filterRecord="fetchDeductionTypes"
    />

    <UiConfirmationComponent
      heading="Deduction Type"
      button-text="Delete"
      :message="`Are you sure you want to delete ${currentSelectedType.title}`"
      :modal="deleteModelShow"
      :is-loading="isModalLoading"
      @confirm="deleteDeductionTypeById"
      @cancel="toogleDeleteModel"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import SalaryTypes from '@src/components/salary-manager/AddSalaryTypesModal.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import isEmpty from 'lodash/isEmpty'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'SalaryDeductionTypeView',
  components: {
    TitleWrapper,
    TableAction,
    Loader,
    Pagination,
    SalaryTypes,
    UiConfirmationComponent: () => import('@src/components/UiElements/UIConfirmationModal.vue'),

    NoRecordFound: () => import('@components/BaseComponent/NoRecordFound.vue'),
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, scrollMixin],
  data() {
    return {
      salary: 'salary',
      dashboard: 'dashboard',
      deleteModelShow: false,
      deductionTypeList: [],
      currentSelectedType: {},
      currentTypeActionBased: null,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      deductionTypeCount: 0,
      filteredRecordLimit: 10,
      forceRender: 0,
      GENERAL_CONSTANTS,
      tableHeads: ['Title', 'Description'],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
      isModalLoading: (state) => state.salary.isModalLoading,
      showSalaryType: (state) => state.salary.showModal,
    }),
  },
  watch: {
    showSalaryType: {
      handler(value) {
        if (!value) this.currentTypeActionBased = null
      },
    },
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.fetchDeductionTypes()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    async deleteDeductionTypeById() {
      await this.deleteDeductionType(this.currentSelectedType.id)
      this.toogleDeleteModel()
      this.fetchDeductionTypes()
    },
    async fetchDeductionTypes(range) {
      const paginationLimit = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      const [res, err] = await this.getDeductionTypes(paginationLimit)
      if (!err) {
        this.deductionTypeList = res.data.records
        this.deductionTypeCount = res.data?.meta?.total_records
        this.setRightbarData(this.deductionTypeCount)
      }
    },

    /**
     * Type Action
     * @param {string} eventName
     * @param {object} currentType
     * @param {number} idx
     * @returns {void}
     * @description Edit or delete current type on the basis of action
     */
    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          this.showModal()
          this.currentTypeActionBased = currentType
          break
        case 'Delete':
          this.currentSelectedType = currentType
          this.toogleDeleteModel()
          break
      }
    },

    setRightbarData(count = null) {
      const rightBarContent = {
        header: {
          title: 'Deduction',
          buttons: [
            {
              title: 'Add Deduction Type',
              classes: ['primary-button-right'],
              action: { name: 'salary/showModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_DEDUCTION_TYPE',
                value: count || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total No of Deduction Type',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('salary', ['getDeductionTypes', 'showModal', 'deleteDeductionType']),
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}

.info {
  top: 35px;
  border: 1px solid var(--text-color-info);
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
}
</style>
